import {SETTINGS} from '../../constants/commons.settings';
import * as CryptoJS from 'crypto-js';

export const encrypt = (field: string) => {
    return (CryptoJS.AES.encrypt(field, SETTINGS.KEYS.SECRET).toString());
};

export const decrypt = (cypherStr: any) => {
    const bytes = (CryptoJS.AES.decrypt(cypherStr.toString(), SETTINGS.KEYS.SECRET));
    return bytes.toString(CryptoJS.enc.Utf8);
};
