export class SETTINGS {

    static ACCESS_TOKEN = '%&^%^*@&*#';
    static REFRESH_TOKEN = '@&^#@#(@&#$';
    static LOGGED_IN_USER = '#$%@^^%';
    static USER_PERMISSIONS_ENC = '^@%#GH@)@';
    static USER_ROLE_ENC = '^%^#HG@(*TK@';

    static KEYS = {
        SECRET: 'iIUsWtNZcf'
    };

    static DOMAIN_CONFIG_KEYS = {
    };

    static DOMAIN_CONSTANTS = {
    };

    static USER_PERMISSIONS: any = [];
    static USER: any = {};
    static STORE: any = {};

    static USER_ROLE = '';

    // static baseURL = 'http://localhost:3000';
    static baseURL = 'https://connect-api.purple.lk';

    static loginURL = '/login';
    static logoutURL = '/logout';


    static REGEX = {
        MOBILE: '^(?:0|94|\\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|5|6|7|8)\\d)\\d{6}$',
        NIC: '^([0-9]{9}[x|X|v|V]|[0-9]{12})$',
        USER_NAME: '^[a-zA-Z0-9]*$'
    };

    static Google = {
        analyticsId : 'UA-159136140-1'
    };
}
