import * as actionTypes from './actionTypes';

export const getPagedIPs = (payload) => {
    const url = `/access/paged-ips`;
    return {
        type: actionTypes.PAGED_IPS,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url,
                data: payload,
                headers: {
                    // showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}): any {
                    if (response.data) {
                        return response.data;
                    }
                },
                onError({getState, dispatch, error}): any {
                    return error;
                },
            }
        }
    };
};

export const whitelistIp = (payload) => {
    const url = `/access/whitelist`;
    return {
        type: actionTypes.PAGED_IPS,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url,
                data: payload,
                headers: {
                    // showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}): any {
                    if (response.data) {
                        return response.data;
                    }
                },
                onError({getState, dispatch, error}): any {
                    return error;
                },
            }
        }
    };
};

export const whitelistIpForAdmin = (payload) => {
    const url = `/access/whitelist-admin`;
    return {
        type: actionTypes.PAGED_IPS,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url,
                data: payload,
                headers: {
                    // showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}): any {
                    if (response.data) {
                        return response.data;
                    }
                },
                onError({getState, dispatch, error}): any {
                    return error;
                },
            }
        }
    };
};

export const deleteIpForAdmin = (ip) => {
    const url = `/access/delete/${ip}`;
    return {
        type: actionTypes.PAGED_IPS,
        payload: {
            request: {
                method: 'DELETE',
                attachAuthToHeader: false,
                url,
                headers: {
                    // showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}): any {
                    if (response.data) {
                        return response.data;
                    }
                },
                onError({getState, dispatch, error}): any {
                    return error;
                },
            }
        }
    };
};
