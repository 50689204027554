export const LOADING = 'LOADING';
export const MANAGE_LOGIN = 'MANAGE_LOGIN';
export const MANAGE_SIGNUP = 'MANAGE_SIGNUP';
export const LOADING_COMPLETED = 'LOADING_COMPLETED';
export const LOGIN = 'LOGIN';
export const LOG_OUT = 'LOG_OUT';
export const RESTORE_LOGIN_DATA = 'RESTORE_LOGIN_DATA';

export const PAGED_IPS = 'PAGED_IPS';
export const CHANGE_PW = 'CHANGE_PW';
