import {decrypt} from './encryptionService';
import {SETTINGS} from '../../constants/commons.settings';

export function isAuthenticated(): any {
    const token = localStorage.getItem(SETTINGS.ACCESS_TOKEN);
    try {
        decrypt(token);
    }catch (e) {
       return false;
    }
    return token !== null || token !== 'null' || token !== undefined;
}
