import React, {Component, Fragment} from 'react';
import Loading from '../components/common/Loading';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {loadingSubs} from '../helpers/utils/LoaderHelper';
import * as actions from '../redux/actions';


class ProtectedLayout extends Component<any, any>{

    state = {
        showLoading: true
    };

    async componentDidMount(): Promise<any> {
        loadingSubs(this.onLoadingChange);
        const data = await this.props.onRestoreLogin();
        this.setState({showLoading: false});
        if (!data.isAuthenticated){
            this.props.history.push('/login');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot): any {
        if (!this.props.isAuthenticated){
            this.props.history.push('/login');
        }
    }

    onLoadingChange = (isLoading) => {
        this.setState({
            showLoading: isLoading
        });
    }


    render(): any {

        const {showLoading} = this.state;
        const {children} = this.props;

        return (
            <Fragment>
                {
                    showLoading && (
                        <Loading/>
                    )
                }
                {this.props.isAuthenticated ? children : null}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.authReducer.isAuthenticated,
        isLoading: state.loadingReducer.isLoading
    };
};

const mapDispatchToProps = {
    onRestoreLogin: () => actions.restoreLoginState(),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProtectedLayout));
