import React from 'react';

import {InlineLoading, Modal, TextInput} from '@carbon/react';
import {Form, message} from 'antd';
import * as actions from '../../redux/actions';
import {connect} from 'react-redux';

const ChangePassword = ({onClose, onChangePassword}) => {
    const formRef: any = React.useRef();
    const [loading, setLoading] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');

    const changePassword = async () => {
        if (loading){
            return message.error('Changing password please wait...');
        }
        try {
            const data = await formRef.current.validateFields();
            setLoading(true);
            await onChangePassword(data);
            onClose(true);
            message.success('Password changed!');
            localStorage.setItem('PASSWORD_CHANGED_ONCE', '1');
        } catch (e: any) {
            setErrorText(e?.response?.data?.message || '');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={true}
            modalHeading='Change User Password'
            modalLabel='Purple Connect'
            primaryButtonText='Change'
            secondaryButtonText='Cancel'
            onRequestClose={() => onClose(true)}
            onRequestSubmit={changePassword}
            preventCloseOnClickOutside={true}
        >
            <Form ref={formRef}>
                <Form.Item
                    name='oldPassword'
                    rules={[{required: true, message: 'Old Password is required'}]}
                >
                    <TextInput
                        data-modal-primary-focus={true}
                        id='text-input-1'
                        labelText='Old Password'
                        placeholder=''
                        type='password'
                    />
                </Form.Item>
                <Form.Item
                    name='password'
                    rules={[{required: true, message: 'New Password is required'}]}
                >
                    <TextInput
                        id='text-input-2'
                        labelText='New Password'
                        placeholder=''
                        type='password'
                    />
                </Form.Item>
                <Form.Item
                    name='confirm'
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value): any {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <TextInput
                        id='text-input-3'
                        labelText='Confirm New Password'
                        placeholder=''
                        type='password'
                    />
                </Form.Item>
            </Form>
            {loading && <InlineLoading
                status='active'
                iconDescription='Changing'
                description='Changing Password...'
            />}
            {errorText && <span style={{color: 'red'}}>{errorText}</span>}
        </Modal>
    );
};


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    onChangePassword: (payload) => actions.changePassword(payload),
};


export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
