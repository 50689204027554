import * as actionTypes from '../actions/actionTypes';

const initialState = {
    user: {},
    accessToken: null,
    isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                user: {
                    ...action.user,
                },
                accessToken: action.accessToken,
                isAuthenticated: action.isAuthenticated
            };
        case actionTypes.LOG_OUT:
            return {
                ...state,
                user: null,
                accessToken: action.accessToken,
                isAuthenticated: action.isAuthenticated
            };
        case actionTypes.RESTORE_LOGIN_DATA:
            return {
                ...state,
                user: {
                    ...action.user,
                },
                accessToken: action.accessToken,
                isAuthenticated: action.isAuthenticated
            };
        default:
            return state;
    }
};

export default authReducer;
