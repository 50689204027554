import React from 'react';

const TemplatePage = React.lazy(() => import('../pages/admin/templates'));

export const routes = [
    {
        path: '/app',
        exact: true,
        component: TemplatePage,
        children: [
            {
                path: '/access-list',
                exact: true,
                component: TemplatePage,
            }
        ]
    },
];
