import {decrypt} from './encryptionService';
import {SETTINGS} from '../../constants/commons.settings';

export const getToken = () => {
    const token = localStorage.getItem(SETTINGS.ACCESS_TOKEN);

    try {
        if (token) {
            return `Bearer ${decrypt(token)}`;
        } else {
            return '';
        }
    }catch (e) {
        return '';
    }

};


export const getRefreshToken = (withoutBearer?) => {
    const token = localStorage.getItem(SETTINGS.REFRESH_TOKEN);

    try {
        if (token) {
            return withoutBearer ? decrypt(token) : `Bearer ${decrypt(token)}`;
        } else {
            return '';
        }
    }catch (e) {
        return '';
    }

};
