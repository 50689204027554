import * as actionTypes from './actionTypes';
import {SETTINGS} from '../../constants/commons.settings';
import {decrypt, encrypt} from '../../helpers/utils/encryptionService';

export const login = (payload: any) => {
    const url = `/admin/login`;
    return {
        type: actionTypes.MANAGE_LOGIN,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url,
                data: payload,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}): any {
                    if (response.data) {
                        initUser(response.data);
                        dispatch({
                            type: actionTypes.LOGIN,
                            user: response.data.user,
                            accessToken: undefined,
                            isAuthenticated: true,
                            isLoading: false
                        });
                        return response;
                    }
                },
                onError({getState, dispatch, error}): any {
                    return error;
                },
            }
        }
    };
};

export const register = (payload: any) => {
    console.log(payload);
    const url = `/admin/sign-up`;
    return {
        type: actionTypes.MANAGE_SIGNUP,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url,
                data: payload,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}): any {
                    if (response.data) {
                        return response;
                    }
                },
                onError({getState, dispatch, error}): any {
                    return error;
                },
            }
        }
    };
};

export const requestAdminPWReset = (payload: any) => {
    const url = `/admin/request-reset-password`;
    return {
        type: actionTypes.MANAGE_SIGNUP,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url,
                data: payload,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}): any {
                    if (response.data) {
                        return response;
                    }
                },
                onError({getState, dispatch, error}): any {
                    return error;
                },
            }
        }
    };
};

export const validatePWResetToken = (payload: any) => {
    const url = `/admin/validate-reset-password`;
    return {
        type: actionTypes.MANAGE_SIGNUP,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url,
                data: payload,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}): any {
                    if (response.data) {
                        return response;
                    }
                },
                onError({getState, dispatch, error}): any {
                    return error;
                },
            }
        }
    };
};

export const updateUserPassword = (payload: any) => {
    const url = `/admin/reset-password`;
    return {
        type: actionTypes.MANAGE_SIGNUP,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url,
                data: payload,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}): any {
                    if (response.data) {
                        return response;
                    }
                },
                onError({getState, dispatch, error}): any {
                    return error;
                },
            }
        }
    };
};


export const changePassword = (payload) => {
    const url = `/admin/change-password`;
    return {
        type: actionTypes.CHANGE_PW,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url,
                data: payload,
                headers: {
                    showLoading: true,
                },
            },
            options: {
                onSuccess({getState, dispatch, response}): any {
                    if (response.data) {
                        return response;
                    }
                },
                onError({getState, dispatch, error}): any {
                    throw error;
                },
            }
        }
    };
};

export const logOut = () => {
    return {
        type: actionTypes.RESTORE_LOGIN_DATA,
        user: {},
        accessToken: null,
        isAuthenticated: false,
        payload: {
            request: {
                method: 'POST',
                attachAuthToHeader: false,
                url: `/admin/logout`,
                data: {},
                headers: {
                    showLoading: true,
                },
            }
        },
        options: {
            onSuccess({getState, dispatch, response}): any {
                clearCache();
            },
            onError({getState, dispatch, error}): any {
                clearCache();
                return error;
            },
        }
    };
};

export const restoreLoginState = () => {

    const userPermissionsENC = localStorage.getItem(SETTINGS.USER_PERMISSIONS_ENC);
    const userRoleENC = localStorage.getItem(SETTINGS.USER_ROLE_ENC);
    const userENC = localStorage.getItem(SETTINGS.LOGGED_IN_USER);

    if (userPermissionsENC && userRoleENC && userENC) {

        try {
            const userStrDEC = decrypt(userENC);
            const user = JSON.parse(userStrDEC);
            SETTINGS.USER_PERMISSIONS = JSON.parse(decrypt(userPermissionsENC));
            SETTINGS.USER_ROLE = decrypt(userRoleENC);
            SETTINGS.USER = user;

            return {
                type: actionTypes.RESTORE_LOGIN_DATA,
                user,
                accessToken: null,
                isAuthenticated: true
            };

        } catch (e) {
            clearCache();
            return {
                type: actionTypes.RESTORE_LOGIN_DATA,
                user: {},
                accessToken: null,
                isAuthenticated: false
            };
        }


    } else {
        clearCache();
        return {
            type: actionTypes.RESTORE_LOGIN_DATA,
            user: {},
            accessToken: null,
            isAuthenticated: false
        };
    }
};


const initUser = (payload) => {
    const {user} = payload;
    if (user) {
        localStorage.setItem(SETTINGS.ACCESS_TOKEN, encrypt(payload.access_token));
        localStorage.setItem(SETTINGS.REFRESH_TOKEN, encrypt(payload.refresh_token));
        localStorage.setItem(SETTINGS.USER_ROLE_ENC, encrypt(user.role?.name));
        localStorage.setItem(SETTINGS.USER_PERMISSIONS_ENC, encrypt(JSON.stringify(user.permissions)));
        localStorage.setItem(SETTINGS.LOGGED_IN_USER, encrypt(JSON.stringify(user)));
        SETTINGS.USER_PERMISSIONS = user.permissions;
        SETTINGS.USER = user;
        SETTINGS.USER_ROLE = user.role?.name;
    }
};

export const clearCache = () => {
    localStorage.removeItem(SETTINGS.ACCESS_TOKEN);
    localStorage.removeItem(SETTINGS.REFRESH_TOKEN);
    localStorage.removeItem(SETTINGS.LOGGED_IN_USER);
    localStorage.removeItem(SETTINGS.USER_PERMISSIONS_ENC);
    localStorage.removeItem(SETTINGS.USER_ROLE_ENC);
    SETTINGS.USER_PERMISSIONS = null;
    SETTINGS.USER = null;
    SETTINGS.USER_ROLE = '';
};
