import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {RouteWithSubRoutes} from './routing/routeWithSubRouts';
import {routes} from './routing/routes';

// Pages
import ProtectedLayout from './hoc/ProtectedLayout';
import ManageLayout from './components/layouts/manage-layout/ManageLayout';
import Loading from './components/common/Loading';

const Login = React.lazy(() => import('./pages/auth/login/login'));
const Register = React.lazy(() => import('./pages/auth/register/register'));
const ForgotPassword = React.lazy(() => import('./pages/auth/forgot-password/forgot-password'));
const ChangePassword = React.lazy(() => import('./pages/auth/change-password/change-password'));
// const NotFoundPage = React.lazy(() => import('./pages/not-found/not-found'));


const Root = (props) => {

    const routing = routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
    ));

    return (
        <div className='App'>
            <React.Suspense fallback={<Loading/>}>
                <Switch>
                    <Route path='/login' component={Login}/>
                    {/*<Route path='/sign-up' component={Register}/>*/}
                    <Route path='/forgot-password' component={ForgotPassword}/>
                    <Route path='/change-password/:token' component={ChangePassword}/>
                    <ProtectedLayout>
                        <ManageLayout>
                            <div>
                                <Route
                                    exact={true}
                                    path='/'
                                    render={() => {
                                        return (
                                            <Redirect to='/app/access-list'/>
                                        );
                                    }}
                                />
                                {routing}
                            </div>
                        </ManageLayout>
                    </ProtectedLayout>
                </Switch>
            </React.Suspense>
        </div>
    );
};

export default Root;
