import * as actionTypes from '../actions/actionTypes';

const initialState = {
    isLoading: false
};

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case actionTypes.LOADING_COMPLETED:
            return {
                ...state,
                isLoading: action.isLoading
            };
        default:
            return state;

    }
};

export default loadingReducer;
