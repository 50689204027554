import React from 'react';
import PropTypes from 'prop-types';
import './ManageLayout.scss';

import {IbmCloudHyperProtectDbaas, UserMultiple, Password, Logout} from '@carbon/icons-react';
import {
    Header,
    HeaderContainer,
    HeaderGlobalBar,
    HeaderMenuButton,
    HeaderName,
    SideNav,
    SideNavItems,
    SideNavLink,
    SkipToContent,
    Theme
} from '@carbon/react';
import {useHistory} from 'react-router-dom';
import ChangePassword from '../../ChangePw/change-password';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import {SETTINGS} from "../../../constants/commons.settings";

const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
};

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    React.useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

const ManageLayout = ({children, onLogout}: any) => {
    const {height, width} = useWindowDimensions();
    const [showChangePw, setShowChangePw] = React.useState(false);
    const history = useHistory();
    React.useEffect(() => {
        if (!localStorage.getItem('PASSWORD_CHANGED_ONCE') && localStorage.getItem('PASSWORD_CHANGED_ONCE') !== '1') {
            setShowChangePw(true);
        }
    }, []);

    return (
        <div className='main-layout'>
            {showChangePw && <ChangePassword onClose={() => {
                localStorage.setItem('PASSWORD_CHANGED_ONCE', '1');
                setShowChangePw(false);
            }}/>}
            <HeaderContainer
                render={({isSideNavExpanded, onClickSideNavExpand}) => (
                    <>
                        <Theme theme='g100'>
                            <Header aria-label='IBM Platform Name'>
                                <SkipToContent/>
                                <HeaderMenuButton
                                    aria-label='Open menu'
                                    onClick={onClickSideNavExpand}
                                    isActive={isSideNavExpanded}
                                />
                                <HeaderName href='#' prefix=''>
                                    Purple Connect
                                </HeaderName>
                                <HeaderGlobalBar>
                                    <HeaderName prefix={SETTINGS.USER.name}/>
                                    {/*<OverflowMenu flipped={true}*/}
                                    {/*              menuOffsetFlip={(menuBody, direction, trigger, flip) => {*/}
                                    {/*                  return {*/}
                                    {/*                      left: 20,*/}
                                    {/*                      top: 10,*/}
                                    {/*                  };*/}
                                    {/*              }}*/}
                                    {/*>*/}
                                    {/*    <OverflowMenuItem itemText='Change Password'/>*/}
                                    {/*    <OverflowMenuItem hasDivider={true} isDelete={true} itemText='Logout'/>*/}
                                    {/*</OverflowMenu>*/}
                                    {/*<HeaderGlobalAction*/}
                                    {/*    aria-label='App Switcher'*/}
                                    {/*    onClick={() => {*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <OverflowMenuVertical/>*/}
                                    {/*</HeaderGlobalAction>*/}
                                </HeaderGlobalBar>
                                <SideNav
                                    aria-label='Side navigation'
                                    expanded={isSideNavExpanded}
                                >
                                    <SideNavItems>
                                        <SideNavLink renderIcon={IbmCloudHyperProtectDbaas}
                                                     onClick={() => history.push('/app/access-list')}>
                                            Mongodb Access
                                        </SideNavLink>
                                        <SideNavLink renderIcon={UserMultiple}
                                                     onClick={() => history.push('/app/users')}>
                                            Users
                                        </SideNavLink>
                                        <SideNavLink renderIcon={Password}
                                                     onClick={() => setShowChangePw(true)}>
                                            Change Password
                                        </SideNavLink>
                                        <SideNavLink renderIcon={Logout}
                                                     onClick={() => {
                                                         onLogout();
                                                         const pwChangedLS = localStorage.getItem('PASSWORD_CHANGED_ONCE');
                                                         localStorage.clear();
                                                         if (pwChangedLS) {
                                                             localStorage.setItem('PASSWORD_CHANGED_ONCE', '1');
                                                         }
                                                     }}>
                                            Logout
                                        </SideNavLink>
                                    </SideNavItems>
                                </SideNav>
                            </Header>
                        </Theme>
                    </>
                )}
            />
            <div style={{padding: width < 1056 ? '4rem 1rem 1rem 1rem' : '5rem 1rem 1rem 17rem'}}>
                {children}
            </div>
        </div>
    );
};
ManageLayout.propTypes = {
    children: PropTypes.element.isRequired,
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    onLogout: () => actions.logOut(),
};


export default connect(mapStateToProps, mapDispatchToProps)(ManageLayout);
