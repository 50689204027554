import React, {Component} from 'react';
import {Loading as Load} from '@carbon/react';

class Loading extends Component<any, any>{

    render(): any {

        return (
            <div style={{
                position: 'fixed',
                top: '0px',
                left: '0px',
                bottom: '0px',
                right: '0px',
                display: 'flex',
                overflow: 'auto',
                backgroundColor: 'rgba(0,0,0,0.22)',
                zIndex: 10
            }}>
                <div style={{
                    margin: 'auto',
                    maxHeight: '100%'
                }}>
                    <Load />
                </div>
            </div>
        );
    }
}

export default Loading;
