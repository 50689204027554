import React from 'react';
import {Route} from 'react-router-dom';

export function RouteWithSubRoutes(route): any {
    return (
        <div>
            <Route
                path={route.path}
                exact={route.exact}
                component={route.component}
                key={route.path}
            />
            {
                route.children && route.children.length > 0 ? route.children.map((subRoute) => (
                   <Route
                       path={route.path + subRoute.path}
                       exact={subRoute.exact}
                       component={subRoute.component}
                       key={subRoute.path}
                   />
               )) : null
            }
        </div>
    );
}
